<script>
import { Doughnut, mixins } from 'vue-chartjs'
import sum from 'lodash/sum'

const numeral = require('numeral')

export default {
  extends: Doughnut,
  props: {
    colors: {
      type: Array,
      required: false,
      default () {
        return ['#0072CE', '#2196F3', '#4FC3F7', '#9FA8DA']
      }
    },
    tooltips: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  mixins: [
    mixins.reactiveProp
  ],
  data () {
    return {
      options: {
        legend: {
          display: false,
          position: 'bottom',
          fullSize: true,
          textDirection: 'rtl',
          labels: {
            fontColor: '#63627C',
            fontSize: 14,
            boxWidth: 10
          }
        },
        tooltips: false,
        // {
        //   display: false,
        //   backgroundColor: '#ffffff',
        //   bodyFontColor: '#455A64'
        // },
        legendCallback: this.customLegend,
        rotation: 0.75,
        cutoutPercentage: 80,
        responsive: false,
        maintainAspectRatio: true,
        animation: {
          easing: 'easeOutQuart',
          animateScale: true
        }
      }
    }
  },
  mounted () {
    this.drawChart()
  },
  watch: {
    chartData () {
      this.drawChart()
    }
  },
  methods: {
    drawDatasets () {
      this.chartData.datasets.forEach((dataset, i) => {
        this.chartData.datasets[i] = Object.assign({}, this.chartData.datasets[i], {
          backgroundColor: this.colors,
          hoverBackgroundColor: this.colors,
          borderWidth: 0,
          borderColor: '#FFFFFF',
          hoverBorderColor: '#FFFFFF'
        })
      })
    },
    drawChart () {
      this.drawDatasets()
      this.renderChart(this.chartData, this.options)
      this.$emit('legend', this.$data._chart.generateLegend())
    },
    customLegend (chart) {
      let html = ''
      if (this.chartData.datasets.length) {
        const dataset = this.chartData.datasets[0]
        const total = numeral(sum(dataset.data)).format('0,0')
        html += `<li class="list-group-item bg-Light"><h4 class="display-Block">Total ${dataset.label}<span class="pull-right">${total}</span></li>`
        dataset.data.forEach((d, i) => {
          html += `
            <li class="list-group-item">
              <span class="chart-legend" style="background-color:${this.colors[i]}"></span>
              <span class="chart-legend__label-text">${this.chartData.labels[i]}<h4 class="pull-right">${numeral(dataset.data[i]).format('0,0')}</h4></span>
            </li>
          `
        })
      }
      return `<ul class="list-group">${html}</ul>`
    }
  }
}
</script>